// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2018 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.

import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {HookService} from "../../hook-service";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubscriptionFormComponent} from "./subscription-form/subscription-form.component";
import {InvoiceFormComponent} from "./invoice-form/invoice-form.component";
import {BillingFormComponent} from "./billing-form/billing-form.component";
import {setupChargebee} from "./globals/chargebee";
import {InvoiceService} from "./invoice-form/invoice.service";
import {SubscriptionService} from "./subscription-form/subscription.service";
import {BillingService} from "./billing-form/billing.service";
import {BuyNowTeaserComponent} from "./buy-now-teaser/buy-now-teaser.component";
import {SubscriptionStatusComponent} from "./subscription-status/subscription-status.component";
import {GDPRPageComponent} from "./gdpr/gdpr-page.component";
import {DPAModal} from "./gdpr/dpa-modal/dpa.modal";
import * as jQuery from "jquery";
import {OpenprojectModalModule} from "core-app/shared/components/modal/modal.module";
import { OpSpotModule } from 'core-app/spot/spot.module';
import { TrialFormComponent } from './trial-form/trial-form.component';
import { TrialActivateComponent } from './trial-activate/trial-activate.component';

jQuery(function () {
  if ((window.gon as any)?.site) {
    setupChargebee();
  }
});

export function initializeSubscriptionsPlugin(injector:Injector) {
    return () => {
        const hookService = injector.get(HookService);
        hookService.register('openProjectAngularBootstrap', () => {
            return [
                { selector: 'subscription-form', cls: SubscriptionFormComponent },
                { selector: 'buy-now-teaser', cls: BuyNowTeaserComponent },
                { selector: 'subscription-status', cls: SubscriptionStatusComponent },
                { selector: 'gdpr-page-component', cls: GDPRPageComponent },
                { selector: 'dpa-modal', cls: DPAModal },
                { selector: 'trial-activate', cls: TrialActivateComponent },
            ];
        });
    };
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        OpSpotModule,
        OpenprojectModalModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initializeSubscriptionsPlugin, deps: [Injector], multi: true },
        InvoiceService,
        SubscriptionService,
        BillingService
    ],
    declarations: [
        SubscriptionFormComponent,
        InvoiceFormComponent,
        BillingFormComponent,
        BuyNowTeaserComponent,
        SubscriptionStatusComponent,
        GDPRPageComponent,
        TrialFormComponent,
        TrialActivateComponent,
        DPAModal
    ]
})

export class PluginModule {
}
